<template>
  <div
    class="container p0"
    v-title
    id="staffMovement"
    :data-title="$t('i18n.staffMovement')"
  >
    <el-tabs
      v-loading="loading"
      type="border-card"
      v-model="active"
      @tab-click="handleCurrentChange(1)"
    >
      <el-tab-pane label="全部" name="1"></el-tab-pane>
      <el-tab-pane label="在线" name="10">
        <div class="list">
          <div
            v-for="(ele, index) in personData"
            :key="index"
            @click="selectElevator(ele, index)"
            :class="{ active: activeEleIndex === index }"
          >
            <span class="index">
              {{ index + 1 + (paging.pageNumber - 1) * 10 }}
            </span>
            <div class="item">{{ ele.name }}</div>
          </div>
        </div>
        <div class="text-center" v-show="total > 0">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div id="jlMap" :style="{ height: $defined.HEIGHT - 130 + 'px' }"></div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue";
import BMapGL from "BMap";
import eleImg from "../../assets/pointMap/end.png";
export default {
  name: "PersonnelGPRS",
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const state = reactive({
      jlMap: "",
      personData: [],
      total: 0,
      loading: false,
      paging: { pageNumber: 1, pageSize: 10, currentState: "" },
      activeEleIndex: "",
      active: "1",
      allData: [],
    });

    const initMap = () => {
      var map = (state.jlMap = new BMapGL.Map("jlMap"));
      map.centerAndZoom(new BMapGL.Point(proxy.$point[0], proxy.$point[1]), 5);
      map.enableScrollWheelZoom(true);
      map.enableDragging();
      map.setMapStyle({ styleJson: proxy.$config.personMapStyle });
      console.log(proxy.$config);
      // map.setMapStyleV2({ styleId: "65801312203374f2da4af504c50cfedc" });
    };

    const initData = () => {
      personPosition();
    };

    const handleCurrentChange = (val) => {
      state.paging.pageNumber = val;
      personPosition();
    };

    const selectElevator = (pt, index) => {
      if (index != -1) {
        // 单个电梯点击事件
        state.activeEleIndex = index;
        state.jlMap.clearOverlays();
      }
      var elePosition = new BMapGL.Point(pt.lng, pt.lat);
      var label = pt.name;
      drawPoint(elePosition, eleImg, label);
      state.jlMap.centerAndZoom(elePosition, 8);
    };

    const drawPoint = (position, img, str) => {
      var icon = new BMapGL.Icon(img, new BMapGL.Size(30, 40));
      var marker = new BMapGL.Marker(position, { icon: icon });
      var label = new BMapGL.Label(str, {
        offset: new BMapGL.Size(20, -10),
      });
      label.setStyle(proxy.$config.labelStyle);
      marker.setLabel(label);
      state.jlMap.addOverlay(marker);
    };

    const personPosition = async () => {
      state.paging.currentState = state.active == 1 ? "" : state.active;
      let person = [];
      if (state.active == 1) {
        state.paging.currentState = "";
        if (state.allData.length > 0) {
          person = state.allData;
        } else {
          const { data } = await proxy.$api.maintenance.personPosition(
            state.paging
          );
          state.allData = person = data;
        }
      } else {
        const { data } = await proxy.$api.maintenance.personPosition(
          state.paging
        );
        state.personData = person = data.records;
        state.total = data.total;
      }
      state.jlMap.clearOverlays();
      let points = [];
      person.map((item) => {
        if (item.lng && item.lat) {
          var elePosition = new BMapGL.Point(item.lng, item.lat);
          var label = item.name;
          points.push(elePosition);
          drawPoint(elePosition, eleImg, label);
        }
      });
      state.jlMap.setViewport(points);
    };

    onMounted(() => {
      initMap();
      initData();
    });

    return {
      ...toRefs(state),
      handleCurrentChange,
      selectElevator,
    };
  },
};
</script>

<style lang="scss">
#staffMovement {
  .el-tabs {
    position: absolute;
    width: 4 * $width + 20;
    z-index: 99;
    top: 30px;
    background: $white;
    left: 31px;
    font-size: $font - 2;
    box-shadow: 0px 3px 10px 0px rgba(30, 111, 72, 0.35);
    .el-tabs__content {
      padding: 0;
    }
    .el-tabs__content {
      max-height: 300px;
    }
    .list {
      padding: 5px;
      height: 250px;
      overflow-y: auto;
      .index,
      .item {
        display: inline-block;
        vertical-align: top;
      }
      .active {
        background: rgba(204, 204, 204, 0.5);
        color: #333;
      }
      > div {
        padding: 10px;
        cursor: pointer;
      }
      .item {
        padding-left: 10px;
      }
    }
  }
  .redDot,
  .yellowDot {
    width: 10px;
    height: 10px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
  }
  .redDot {
    background: #d02c2f;
    animation: scale 2s infinite;
  }
  .yellowDot {
    background: #fdbf01;
  }
  @keyframes scale {
    @for $i from 0 through 10 {
      #{$i * 10%} {
        transform: scale($i / 10);
        opacity: $i / 10;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  #staffMovement {
    margin-top: -20px;
    .el-tabs {
      width: 200px;
      left: 10px;
      top: 10px;
    }
  }
}
</style>
